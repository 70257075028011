/**
 * 抽奖状态管理
 */
import axios from "axios";
export default {
  namespaced: true,
  state: {
    drawId: null,
    liveId: null,
    prizeId: null,
    maxDeductGoldCoin: 0,
  },
  mutations: {
    setLottery(state, obj) {
      state.drawId = obj.id;
      state.prizeId = obj.prizeId;
      state.liveId = obj.liveId;
      state.maxDeductGoldCoin = obj.maxDeductGoldCoin;
      // console.log("打开抽奖:", JSON.stringify(obj));
    },
  },
  actions: {
    initDraw({ commit }, drawId) {
      return new Promise((resolve, reject) => {
        axios
          .get("/dlr-wapp/api/lottery/getDraw", { params: { drawId } })
          .then((res) => {
            // debugger
            commit("setLottery", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
