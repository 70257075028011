<template>
  <div class="container">
    <div class="chatContainer" ref="scrollDiv" v-scroll="onScroll">
      <div ref="chatScroll" class="chatScrollWrap">
        <div
          class="chatItem"
          :key="index"
          v-for="(chatItem, index) in chatShowList"
        >
          <div class="userInfo">
            <img :src="chatItem.avatar || defaultAvatar" alt />
          </div>
          <div class="chatWrap">
            <div class="nickName">{{ chatItem.nickName }}</div>
            <div class="chatMsg">{{ chatItem.chatContext }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showInputTip" class="chatInputTip">正在输入...</div>
    <div class="chatInputWrap">
      <div class="leftWrap">
        <img src="@assets/images/live/edit.png" />
        <input
          ref="inputRef"
          type="text"
          v-model="chatContext"
          placeholder="请输入内容"
        />
      </div>
      <button class="sendBt" @click="inputChat">发送</button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { mapGetters } from "vuex";
import { defaultAvatar } from "@/utils/constant";
export default {
  name: "GiftPop",
  data: function () {
    return {
      showInputTip: false,
      chatContext: "",
      defaultAvatar: defaultAvatar,
      pageNo: 1,
      size: 20,
      loadend: false,
      chatList: [],
      chatShowList: [],
      position: { scrollTop: 0, scrollLeft: 0 },
    };
  },
  created() {
    this.onRefreshChat();
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.$Bus.$on("sendMsg", (msg) => {
      let jsonData = JSON.parse(msg.data);
      let param = {
        chatContext: jsonData.chatContext,
        liveId: this.live.id,
        nickName: msg.nickName,
        avatar: msg.avatar,
      };
      if (this.live.id == msg.liveId) {
        this.chatShowList.push(param);
      }
    });
    this.$Bus.$on("sendGift", (msg) => {
      let jsonData = JSON.parse(msg.data);
      let param = {
        chatContext: "送来了礼物," + jsonData.giftName,
        liveId: this.live.id,
        nickName: msg.nickName,
        avatar: msg.avatar,
      };
      if (this.live.id == msg.liveId) {
        this.chatShowList.push(param);
      }
    });
  },
  computed: mapGetters(["live", "userInfo"]),
  methods: {
    onRefreshChat() {
      this.pageNo = 1;
      this.loadend = false;
      this.chatList = [];
      this.getChatList();
    },
    getChatList() {
      let that = this;
      let param = {
        liveId: that.live.id,
        page: that.pageNo,
        size: that.size,
      };
      console.log("刷新.........");
      // debugger
      if (that.loadend) {
        return;
      }
      // if (this.loadend) return;
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      this.$http
        .get("/dlr-wapp/chat/liveChatList", { params: param })
        .then((res) => {
          that.loadend = res.data.length < that.size;
          that.chatList.push.apply(that.chatList, res.data);
          that.chatShowList = JSON.parse(JSON.stringify(that.chatList));
          that.chatShowList.reverse();
          that.pageNo++;
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        })
        .finally(() => {
          toast.clear(); //清除加载效果
        });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let scrollHeight = this.$refs.chatScroll.scrollHeight;
        this.$refs.scrollDiv.scrollTop = scrollHeight;
      });
    },
    onScroll: function (e, position) {
      this.position = position;
      if (position.scrollTop === 0) {
        if (!this.loadend) {
          this.getChatList();
          // console.log("加载数据............");
        }
      }
    },
    inputChat() {
      if (!this.chatContext) {
        this.$toast("请输入内容！");
        return;
      }
      let param = {
        chatContext: this.chatContext,
        liveId: this.live.id,
        nickName: this.userInfo.nickName,
        avatar: this.userInfo.avatar,
      };
      // debugger
      this.showInputTip = true;
      this.chatContext = "";
      this.$http
        .post("/dlr-wapp/chat/insertChat", param)
        .then((res) => {
          // debugger
          this.chatShowList.push(res.data);
        })
        .catch((err) => {
          this.$toast.fail(err.message);
        })
        .finally(() => {
          this.showInputTip = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  .chatContainer {
    margin-top: 180px;
    width: 100vw;
    height: 78vh;
    margin-bottom: 110px;
    /*background-color: #262626;*/
    overflow-y: scroll;
    /* z-index: 1000; */
    .chatScrollWrap {
      display: flex;
      flex-direction: column;
      /*height: 800px !important;*/
      /* margin-top: 110px; */
      /* background-color: #00aaea; */
      align-items: flex-start;
      justify-content: flex-end;
      color: #fff;
      .chatItem {
        display: flex;
        flex-direction: row;
        /* background-color: #f0c34c; */
        margin-bottom: 20px;
        .userInfo {
          margin-left: 50px;
          img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
          }
        }
        .chatWrap {
          /* width: 200px; */
          /* height: 200px; */
          background-color: #fff;
          padding: 7px 30px 18px 30px;
          margin-left: 12px;
          border-radius: 7px;
          .nickName {
            color: #50a050;
            font-size: 28px;
          }
          .chatMsg {
            font-size: 26px;
            /* font-family: PingFang SC; */
            font-weight: 500;
            line-height: 34px;
            color: #262626;
            /* letter-spacing: 20px; */
            opacity: 1;
          }
        }
      }
    }
  }
  .chatInputTip {
    position: fixed;
    bottom: 160px;
    //z-index: 10;
    //border: 1px solid #f4b409;
    right: 30%;
    font-size: 30px;
    color: #7a7a7a;
  }
  .chatInputWrap {
    position: fixed;
    bottom: 46px;
    /* width: 485px; */
    height: 64px;
    left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    //z-index: 3;
    /* background-color: #5eae72; */
    .leftWrap {
      background-color: rgba(0, 0, 0, 0.39);
      border-radius: 32px;
      /*height: 64px;*/
      /*line-height: 64px;*/
      height: 100%;
      color: #fff;
      display: flex;
      align-items: center;
      input {
        /*background-color: #fe960f;*/
        /*border: 1px solid #00aaea;*/
        width: 400px;
        height: 100%;
        text-align: left;
        /*border-radius: 32px;*/
        font-size: 26px;
        color: #fff;
        padding-left: 15px;
        padding-right: 30px;
        border-bottom-right-radius: 32px;
        border-top-right-radius: 32px;
        &::placeholder {
          color: #fff;
        }
      }
      img {
        width: 38px;
        height: 38px;
        margin-left: 31px;
      }
    }
    button {
      /*width: 300px;*/
      height: 64px;
      width: 160px;
      /* display: flex; */
      background-color: #5eae72;
      border-radius: 32px;
      line-height: 64px;
      font-size: 24px;
      color: #fff;
      margin-left: 20px;
      text-align: center;
      /* border:1px solid #FDD; */
    }
  }
}
</style>
