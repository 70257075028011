// import store from "@utils/store/cookie";
// import { getUserInfo } from "@api/user";
import dialog from "@utils/dialog";
import axios from "axios";

// const LOGIN_KEY = "login_status";

const state = {
  // footer: true,
  // home: true,
  // homeActive: false,
  token: null,
  webToken: null,
  userInfo: null,
  expires_time: null,
  color:''
  // storeItems: store.get("storeItems") || null,
  // goName: store.get("goName") || "",
};

const mutations = {
  SHOW_FOOTER(state) {
    state.footer = true;
  },
  HIDE_FOOTER(state) {
    state.footer = false;
  },
  SHOW_HOME(state) {
    state.home = true;
  },
  HIDE_HOME(state) {
    state.home = false;
  },
  OPEN_HOME(state) {
    state.homeActive = true;
  },
  CLOSE_HOME(state) {
    state.homeActive = false;
  },
  LOGIN(state, token, expires_time) {
    state.token = token;
    state.expires_time = expires_time;
    // console.log("设置token:" + token);
    // store.set(LOGIN_KEY, token, expires_time);
  },
  // LOGOUT(state) {
  //   state.token = null;
  //   state.expires_time = null;
  // },
  BACKGROUND_COLOR(state, color) {
    state.color = color;
    document.body.style.backgroundColor = color;
  },
  UPDATE_USERINFO(state, userInfo) {
    state.userInfo = userInfo;
    state.webToken = userInfo.id + "_h5";
  },
  // GET_STORE(state, storeItems) {
  //   state.storeItems = storeItems;
  //   store.set("storeItems", storeItems);
  // },
  // GET_TO(state, goName) {
  //   state.goName = goName;
  //   store.set("goName", goName);
  // },
};

const actions = {
  USERINFO({ commit }) {
    return new Promise((resolve) => {
      axios.get("/dlr-wapp/userinfo", {}).then((res) => {
        commit("UPDATE_USERINFO", res.data);
        resolve(res.data);
      });
    }).catch(() => {
      dialog.error("获取信息失败!");
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
