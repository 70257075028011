<template>
  <!-- <iframe v-if="game.url!=''" :class="{show:game.open}" :src="game.url" frameborder="0" id="gameFrame"></iframe> -->
  <transition enter-active-class="fadeInUp">
    <object
      ref="gameFrame"
      :class="{ show: liveGame.open }"
      :data="liveGame.url"
      frameborder="0"
      id="gameFrame"
    ></object>
    <!-- <object :class="{show:game.open}" :data="game.url" frameborder="0" id="gameFrame"></object> -->
  </transition>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      liveGame: (state) => state.liveGame,
    }),
  },
  mounted() {
    this.$Bus.$on("goHome", () => {
      this.$router.replace({ path: "/" });
    });
    this.$Bus.$on("endGame", () => {
      this.$router.push("/game/gameRank");
    });
  },
};
</script>

<style scoped>
#gameFrame {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  /*background-image: url("～@/assets/images/");*/
  /*background-image: url("https://img.hidongtv.com/hidong-h5/img/game/loading.png");*/
  /*background-size: cover;*/
}
</style>
