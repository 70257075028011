/**
 * 登录状态管理
 */
import axios from "axios";
import $store from "@/store";
export default {
  namespaced: true,
  state: {
    id: null,
    status: 0,
    url: "",
    open: false,
  },
  mutations: {
    setLiveGameId(state, val) {
      state.id = val;
    },
    upStatus(state, val) {
      state.status = val;
    },
    setInfo(state, obj) {
      state.id = obj.id;
      state.status = obj.status;
      state.url = obj.url;
    },
    openGame(state, gameUrl) {
      // debugger
      const token = $store.state.app.token;
      const expires_time = $store.state.app.expires_time;
      state.open = true;
      state.status = 0;
      state.url = gameUrl + "&token=" + token + "&expires_time=" + expires_time;
      console.log("gameUrl:", state.url);
    },
  },
  actions: {
    joinGame({ commit }, liveGameId) {
      return new Promise((resolve, reject) => {
        axios
          .post("/dlr-wapp/liveGame/joinGame", { id: liveGameId })
          .then((res) => {
            // debugger
            commit("openGame", res.data.gameUrl);
            commit("setLiveGameId", liveGameId);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getLiveGame({ commit }, liveGameId){
      return new Promise((resolve, reject) => {
        axios
            .get("/dlr-wapp/liveGame/getLiveGameInfo", {params:{id:liveGameId}})
            .then((res) => {
              // debugger
              commit("setInfo", res.data);
              resolve(res.data);
            })
            .catch((e) => {
              reject(e);
            });
      });
    }
  },
};
