<template>
  <div class="noContainer">
    <img src="@/assets/icons/icon_no_data.png" />
    <span>没有数据~</span>
  </div>
</template>

<script>
export default {
  name: "NoData",
};
</script>

<style lang="less" scoped>
.noContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //border: 1px solid #FF33DD;
  margin-top: 30px;
  img {
    width: 120px;
    //height: 300px;
    //border: 1px solid #33E;
  }
  span {
    font-size: 24px;
    color: #7c848e;
    margin-top: 10px;
  }
}
</style>
