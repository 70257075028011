import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "./utils/axios-config";
import $scroll from "@utils/loading";
import store from "./store";
import "@assets/css/base.css";
import "@assets/css/reset.css";
import "@assets/css/style.css";
import "amfe-flexible";
import * as socketApi from "./utils/socket";
import vuescroll from "vue-scroll";
// 完整加载---引入+全局注册
import VueLuckyCanvas from '@lucky-canvas/vue'
import {
  Popup,
  Tab,
  Tabs,
  Form,
  Field,
  Dialog,
  CellGroup,
  Button,
  Cascader,
  ActionSheet,
  Tag,
  Toast,
  Card,
  Badge
} from "vant";

Vue.config.productionTip = false;
Vue.use(VueLuckyCanvas);
Vue.use(vuescroll);
Vue.use(Popup);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Form);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(Button);
Vue.use(Cascader);
Vue.use(ActionSheet);
Vue.use(Tag);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Card);
Vue.use(Badge);
Vue.prototype.$socketApi = socketApi;
Vue.prototype.$Bus = new Vue();
Vue.prototype.$toast = Toast;
Vue.prototype.$scroll = $scroll;

const createjs = window.createjs;
Vue.prototype.$createjs = createjs;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
