<template>
  <div :class="showSign ? 'signContainer' : 'signContainer-s'">
    <van-action-sheet
      v-model="showWish"
      :actions="wishActions"
      @select="onSelectWish"
    />
    <!--    <van-popup-->
    <!--      style="padding: 10px"-->
    <!--      v-model="showRelationShip"-->
    <!--      round-->
    <!--      position="bottom"-->
    <!--    >-->
    <!--      <van-cascader-->
    <!--        active-color="#50A050"-->
    <!--        v-model="cascaderValue"-->
    <!--        title="您的活动身份"-->
    <!--        :options="options"-->
    <!--        @close="showRelationShip = false"-->
    <!--        @finish="onFinish"-->
    <!--      />-->
    <!--    </van-popup>-->
    <div v-show="showSign" class="signContainer">
      <div class="signWrap">
        <div class="headPicWrap">
          <img :src="userInfo.avatar" />
        </div>
        <div class="signFormWrap">
          <!--          <div class="signItem" @click="goShowRelation">-->
          <!--            <span>{{ relationShip }}</span>-->
          <!--            <img src="@assets/images/right.png" />-->
          <!--          </div>-->
          <div class="signItem" @click="goShowWish">
            <span>{{ wish }}</span>
            <img src="@assets/images/right.png" />
          </div>
          <div>
            <van-button
              size="large"
              style="width: 120px"
              round
              block
              type="primary"
              @click="sign"
              native-type="sign"
            >
              签到
            </van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters } from "vuex";
import { getDefaultBless } from "@utils/constant";
import store from "@/store";
export default {
  name: "Sign",
  data: function () {
    return {
      userInfo: {},
      wishActions: [],
      showSign: false,
    };
  },
  computed: mapGetters(["live"]),
  created() {
    store.dispatch("USERINFO").then((res) => {
      // 获取用户信息
      console.log("获取用户信息++++++++" + JSON.stringify(res));
      this.userInfo = res;
      this.getSign();
      this.getSignList();
    });
  },
  // mounted() {
  //   this.wishActions = getDefaultBless(this.live.liveType);
  // },
  setup() {
    const options = [
      {
        text: "男方",
        value: "1",
        children: [
          { text: "兄弟", value: "11" },
          { text: "姐妹", value: "12" },
          { text: "表兄弟", value: "13" },
          { text: "表姐妹", value: "14" },
          { text: "舅舅", value: "15" },
          { text: "姑姑", value: "16" },
          { text: "朋友", value: "17" },
          { text: "家人", value: "18" },
        ],
      },
      {
        text: "女方",
        value: "2",
        children: [
          { text: "兄弟", value: "21" },
          { text: "姐妹", value: "22" },
          { text: "表兄弟", value: "23" },
          { text: "表姐妹", value: "24" },
          { text: "舅舅", value: "25" },
          { text: "姑姑", value: "26" },
          { text: "朋友", value: "27" },
          { text: "家人", value: "28" },
        ],
      },
    ];
    const showRelationShip = ref(false);
    const relationShip = ref("请选择您的活动身份");
    // 全部选项选择完毕后，会触发 finish 事件
    const onFinish = ({ selectedOptions }) => {
      showRelationShip.value = false;
      // this.showRelationShip = false
      relationShip.value = selectedOptions
        .map((option) => option.text)
        .join("/");
    };
    const showWish = ref(false);
    // const wishActions = getDefaultBless(this.live.liveType);
    const wish = ref("请选择祝福语");
    const onSelectWish = (item) => {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      // debugger
      showWish.value = false;
      console.log("选中:", item.name);
      wish.value = item.name;
      // showToast(item.name);
    };
    return {
      options,
      showRelationShip,
      showWish,
      onSelectWish,
      onFinish,
      wish,
      relationShip,
    };
  },
  methods: {
    sign() {
      let param = {
        liveId: this.live.id,
        wish: this.wish,
        relationShip: this.relationShip,
      };
      if (this.wish === "请选择祝福语") {
        this.$toast("请选择祝福语!");
        return;
      }
      this.$http.post("/dlr-wapp/live/sign", param).then(() => {
        this.showSign = false;
        this.$toast.success("签到成功!");
      });
    },
    goShowWish() {
      this.showWish = true;
    },
    goShowRelation() {
      this.showRelationShip = true;
    },
    getSign() {
      this.$http
        .get("/dlr-wapp/live/checkSign", { params: { id: this.live.id } })
        .then((res) => {
          this.showSign = !res.data;
        });
    },
    getSignList() {
      this.$http
        .get("/dlr-wapp/liveBlessWord/getList", {
          params: { liveId: this.live.id },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.wishActions = res.data;
          } else {
            this.wishActions = getDefaultBless(this.live.liveType);
          }
        });
    },
  },
};
</script>

<style scoped>
.signContainer {
  position: fixed;
  /* top: 0; */
  /* width: 550px; */
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  /* z-index: 100000; */
  background-color: rgba(0, 0, 0, 0.6);
}

.signContainer-s {
  position: fixed;
  /* top: 0; */
  /* width: 550px; */
  width: 100vw;
  /*height: 100vh;*/
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /*z-index: 1;*/
  /* z-index: 100000; */
  /*background-color: rgba(0, 0, 0, 0.6);*/
}

.signContainer .signWrap {
  width: 550px;
  background-color: #fff;
  border-radius: 16px;
  /* padding: 20px 0px; */
  position: relative;
  display: flex;
  justify-content: center;
  height: 400px;
}

.signContainer .signWrap .headPicWrap {
  position: absolute;
  /* background-color:#eb8f00!important;  */
  /* opacity:1; */
  top: -85px;
  height: 177px;
  /* background-color: #ff8a00; */
  /* z-index: 10000; */
  /* margin-bottom: 30px; */
}

.signContainer .signWrap .headPicWrap img {
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.signContainer .signWrap .signFormWrap {
  /* background-color: #FF2; */
  width: 100%;
  border-radius: 16px;
  /* padding: 0 10px; */
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 68px;
}

.signContainer .signWrap .signFormWrap .signItem {
  width: 490px;
  height: 88px;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #cccccc;
  font-size: 28px;
  border-radius: 11px;
  padding: 20px;
}

.signContainer .signWrap .signFormWrap .signItem img {
  width: 30px;
  height: 30px;
}
</style>
