<template>
  <van-popup v-model="showPrize" round>
    <div class="prizeWrap">
      <img :src="prize.picUrl"/>
      <span>{{prize.prizeName}}</span>
      <span class="tip">恭喜您中奖了！</span>
    </div>
  </van-popup>
</template>

<script>
export default {
  name: "WinnerNotice",
  data() {
    return {
      showPrize:false,
      prize:{}
    };
  },
  mounted() {
    this.$Bus.$on("noticeWinner", msg => {
      console.log('消息-----',msg)
      const jsonData = JSON.parse(msg);
      this.showPrize = true;
      this.prize = jsonData
    });
  }
};
</script>

<style lang="less" scoped>
.prizeWrap{
  width: 600px;
  height: 640px;
  //padding: 20px 0;
  background-image: url("~@/assets/images/live/bg_get_win.gif");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  box-sizing: border-box;
  img{
    width: 260px;
  }
  span{
    margin-top: 20px;
    font-size: 56px;
    font-weight: 500;
    color: #FFF;
  }
  .tip{
    color: red;
    text-shadow: 0 -2px #fff, 2px 0px #fff, 0 2px #fff, -2px 0 #fff,
      -2px -2px #fff, 2px 2px #fff, 2px -2px #fff, -2px 2px #fff;
  }
}
</style>
