<template>
  <div class="container">加载中...</div>
</template>

<script>
import wx from "weixin-js-sdk";
import { parseQuery } from "@utils";

export default {
  name: "AuthSuccess",
  mounted() {
    this.$toast.success("授权成功！");
    const liveId = this.$route.query.liveId || parseQuery()["liveId"];
    wx.miniProgram.navigateTo({
      url: `/pages/liveWelcome/liveWelcome?liveId=${liveId}`,
    });
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  height: 100vh;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
</style>
