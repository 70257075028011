<template>
    <div
      id="svgaCanvas"
      v-show="isPlayGift"
      class="svgaContainer"
      ref="canvas"
    ></div>
</template>

<script>
import SVGA from "svgaplayerweb";
export default {
  name: "SvgPlayer",
  data: function () {
    return {
      isPlayGift: false
    };
  },
  mounted() {
    this.$Bus.$on("sendGift", (msg) => {
      let jsonData = JSON.parse(msg.data);
      if (this.live.id == msg.liveId) {
        this.playSvg(jsonData.phoneSvgUrl);
      }
    });
    this.$Bus.$on("playSvg", (phoneSvgUrl) => {
      this.playSvg(phoneSvgUrl);
    });
  },
  methods: {
    playSvg(svgaUrl) {
      var player = new SVGA.Player("#svgaCanvas");
      var parser = new SVGA.Parser("#svgaCanvas");
      // let svgaPath = '/svga/love_socket_app_1683626395814.svga'
      let that = this;
      that.isPlayGift = true;
      this.$nextTick(() => {
        //这里使用动态加载的方式，加载tableData返回的svga源（例如：http://a.svga)
        // console.log("svgaUrl" + svgaUrl);
        parser.load(svgaUrl, function (videoItem) {
          player.loops = 1;
          player.setVideoItem(videoItem);
          player.startAnimation();
          player.onFinished(() => {
            // console.log("播放结束");
            that.isPlayGift = false;
          });
          // player.clearsAfterStop = true //player有很多属性，根据需要设置
        });
      });
    },
  },
};
</script>

<style scoped>
.svgaContainer {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: #FDD; */
  /* z-index: 1000; */
}
</style>
