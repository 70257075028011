<template>
  <div class="sideBarContainer">
    <div class="popWrapper">
      <canvas id="popCanvas" ref="popCanvas"></canvas>
    </div>
    <van-badge :content="noReceivedCount">
      <div class="BarItem" @click="openPrize">
        <img src="@assets/icons/icon_prize.png" />
        <span>奖品</span>
      </div>
    </van-badge>
    <div class="BarItem" v-if="live.goldLotterySwitch" @click="openDraw">
      <img src="@assets/icons/icon_draw.png" />
      <span>抽奖</span>
    </div>
    <div class="BarItem" @click="openGift">
      <img src="@assets/icons/icon_v_gift.png" />
      <span>礼物</span>
    </div>
    <van-popup
      v-model="showPrize"
      position="bottom"
      round
      :style="{
        width: '100%',
        minHeight: '40%',
        paddingTop: '20px',
        background: '#f2f2f2',
      }"
    >
      <div class="prizeItem" v-for="(item, index) in prizeResult" :key="index">
        <div class="mainWrap">
          <img :src="item.dlrLotteryPrize.picUrl" />
          <div class="infoWrap">
            <span class="title">{{ item.dlrLotteryPrize.prizeName }}</span>
            <!--            <van-tag round size="large" plain color="#333">未领奖</van-tag>-->
          </div>
        </div>
        <div class="slaveWrap">
          <div class="left">
            <span class="prizeName">扣除金币：{{ item.deductGoldCoin }}个</span>
            <span>中奖时间 {{ item.createTime }}</span>
          </div>
        </div>
        <div
          class="statusWrap"
          :style="{ backgroundImage: 'url(' + statusBg(item.isReceived) + ')' }"
        ></div>
        <div class="btWrap" v-show="!item.isReceived" @click="openReceiveQrCode(item.id)">领取</div>
      </div>
      <NoData v-if="prizeResult.length == 0"></NoData>
    </van-popup>
    <van-popup v-model="showDraw" round class="lotteryContainer">
      <LuckyWheel
        ref="myLucky"
        width="260px"
        height="260px"
        :accelerationTime="10"
        :decelerationTime="10"
        :prizes="prizes"
        :blocks="blocks"
        :buttons="buttons"
        @start="startCallback"
        @end="endCallback"
      />
      <div class="tip">每次抽奖消耗{{ live.goldThreshold }}个金币</div>
    </van-popup>
    <van-popup v-model="showWriteOffQrCode" round class="lotteryContainer">
      <img class="qrCodeImg" :src="writeOffQrCodeUrl" />
      <div class="tip">核销码</div>
    </van-popup>
    <van-popup
      v-model="showGift"
      position="bottom"
      round
      :style="{ width: '100%', height: '40%', background: '#262626' }"
    >
      <div class="giftListContainer">
        <div class="giftListWrap">
          <div
            class="giftItem"
            @mouseover="addActive($event)"
            @mouseout="removeActive($event)"
            v-for="item in giftList"
            :key="item.id"
          >
            <img :src="item.coverUrl" alt />
            <div class="giftName">{{ item.giftName }}</div>
            <div class="price">{{ item.rewardColdCoin }}金币</div>
            <div class="sendGiftBt" @click="sendGiftFun(item)">购买</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import JumpBubble from "@libs/JumpBubble";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import NoData from "@components/NoData";
export default {
  name: "GiftPop",
  components: {
    NoData,
  },
  data: function () {
    return {
      showWriteOffQrCode: false,
      showPrize: false,
      showGift: false,
      showDraw: false,
      pageNo: 1,
      roomName: "",
      writeOffQrCodeUrl: "",
      giftLimit: 8,
      activeGift: "a",
      giftList: [],
      prizeResult: [],
      noReceivedCount:0,
      drawIdx: 0,
      prizeFontSize: "14px",
      prizeLengthLimit: "60%",
      prizeFontColor: "#FFF",
      prizeTop: "10px",
      blocks: [{ padding: "10px", background: "#9b3620" }],
      prizes: [],
      buttons: [
        { radius: "50%", background: "#9b3620" },
        { radius: "45%", background: "#c9781c" },
        {
          radius: "40%",
          background: "#9b3620",
          pointer: true,
          fonts: [{ text: "开始\n抽奖", top: "-20px" }],
        },
      ],
    };
  },
  created() {
    this.getGiftList();
    this.getPrizeData();
    this.$store.dispatch("live/getLiveInfo", this.live.id).then(() => {
      this.prizes = [
        {
          background: "#079445",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "金币在手,天下我有！",
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
        {
          background: "#1a2829",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "红包¥1",
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
        {
          background: "#079445",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "最大醉强,再创辉煌！",
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
        {
          background: "#1a2829",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "红包¥" + this.live.maxPrizeVal / 2,
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
        {
          background: "#079445",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "阿祖,住手吧！",
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
        {
          background: "#1a2829",
          fonts: [
            {
              top: this.prizeTop,
              fontColor: this.prizeFontColor,
              text: "红包¥" + this.live.maxPrizeVal,
              fontSize: this.prizeFontSize,
              lengthLimit: this.prizeLengthLimit,
            },
          ],
        },
      ];
    });
  },
  mounted() {
    this.$nextTick(function () {
      this.initPop();
    });
  },
  computed: {
    ...mapGetters(["live", "userInfo"]),
  },
  methods: {
    openReceiveQrCode(id) {
      this.showPrize = false;
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      this.$http
        .get("/dlr-wapp/api/dlrLotteryResult/getVerifyCode", {
          params: { resultId: id },
        })
        .then((res) => {
          this.writeOffQrCodeUrl = res.data;
          this.showWriteOffQrCode = true;
        })
        .finally(() => {
          toast.clear();
        });
    },
    statusBg(isReceived) {
      if (isReceived) {
        return require("@/assets/icons/icon_received.png");
      } else {
        return require("@/assets/icons/icon_no_receive.png");
      }
    },
    async openPrize() {
      await this.getPrizeData();
      this.showPrize = true;
    },
    async getPrizeData(){
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "加载中...",
      });
      await this.$http
        .get("/dlr-wapp/api/dlrLotteryResult/getMyLottery", {
          params: { liveId: this.live.id },
        })
        .then((res) => {
          this.prizeResult = res.data;
          this.noReceivedCount = res.data.filter(item => item.isReceived === false).length;
        })
        .finally(() => {
          toast.clear();
        });
    },
    openDraw() {
      this.showDraw = true;
    },
    // 点击抽奖按钮会触发star回调
    startCallback() {
      this.$http
        .get("/dlr-wapp/api/lottery/draw", { params: { liveId: this.live.id } })
        .then((res) => {
          // 调用抽奖组件的play方法开始游戏
          this.$refs.myLucky.play();
          this.drawIdx = res.data;
          setTimeout(() => {
            // 调用stop停止旋转并传递中奖索引
            this.$refs.myLucky.stop(res.data);
          }, 3000);
        })
        .catch((err) => {
          this.showDraw = false;
          let that = this;
          if (err.message.includes("金币不足")) {
            this.$toast("金币不足，买礼物送10倍金币！");
            setTimeout(() => {
              that.openGift();
            }, 2000);
          } else {
            this.$toast.fail(err.message);
          }
        });
    },
    // 抽奖结束会触发end回调
    endCallback(prize) {
      console.log("返回：" + JSON.stringify(prize));
      this.showDraw = false;
      setTimeout(() => {
        this.$Bus.$emit("refreshCoin");
        if (this.drawIdx === 0) {
          this.$toast(prize.fonts[0].text);
        } else {
          this.$toast("恭喜您，抽中" + prize.fonts[0].text);
        }
      }, 1000);
    },
    initPop() {
      let popCanvas = this.$refs.popCanvas;
      popCanvas.width = 100;
      popCanvas.height = 300;
      const bubble = new JumpBubble(popCanvas);
      // bubble.config.width=25;
      bubble.config.cavWidth = 50;
      let list = [
        "https://qiniu.dljok.com/icons/pingpang.png",
        "https://qiniu.dljok.com/icons/skate.png",
        "https://qiniu.dljok.com/icons/volleyball.png",
        //注：路径是相对html的路径，因为该路径最终会放到img标签的src上
      ];
      setInterval(() => {
        list.forEach((item) => {
          bubble.create(item);
        });
      }, 250);
    },
    sendGiftFun(item) {
      // debugger
      // let that = this;
      let param = {
        giftId: item.id,
        liveId: this.live.id,
      };
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "发送礼物...",
      });
      this.$http
        .post("/dlr-wapp/gift/sendGift", param)
        .then((res) => {
          if (res.data.isSend) {
            this.showGift = false;
            this.$Bus.$emit("refreshCoin");
            this.$Bus.$emit("playSvg", item.phoneSvgUrl);
            this.$toast.success("发送成功！");
          } else {
            wx.miniProgram.navigateTo({
              url: `/pages/wxpay/wxpay?orderId=${res.data.orderId}&payTpe=gift`,
            });
          }
        })
        .finally(() => {
          toast.clear();
        });
    },
    addActive(event) {
      event.currentTarget.className = "giftItem-active";
    },
    removeActive(event) {
      event.currentTarget.className = "giftItem";
    },
    openGift() {
      this.showGift = true;
    },
    getGiftList() {
      // debugger
      let that = this;
      let param = {
        liveId: that.live.id,
        page: that.pageNo,
        size: that.giftLimit,
      };
      const toast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "礼物加载中...",
      });
      this.$http
        .get("/dlr-wapp/gift/getGiftList", { params: param })
        .then((res) => {
          that.giftList = res.data;
        })
        .catch((err) => {
          that.$dialog.error(err.msg);
        })
        .finally(() => {
          // that.loading = false;
          toast.clear(); //清除加载效果
        });
    },
  },
};
</script>

<style lang="less" scoped>
.sideBarContainer {
  position: absolute;
  right: 30px;
  display: flex;
  flex-direction: column;
  bottom: 150px;
  .popWrapper {
    width: 100px;
    position: relative;
    bottom: -50px;
  }
  .BarItem {
    display: flex;
    width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    //padding: 10px 0;
    padding: 15px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-weight: 800;
    margin-bottom: 20px;
    box-sizing: border-box;
    border-radius: 20px;
    img {
      width: 64px;
      height: 64px;
      //border: 1px solid #FFF;
      margin-bottom: 5px;
    }
    &:hover {
      background-color: #28c76f;
      opacity: 0.8;
      border: 2px solid #28c76f;
      box-sizing: border-box;
    }
  }
  .giftListContainer {
    width: 100%;
    .giftListWrap {
      display: flex;
      flex-direction: row;
      .giftItem {
        /* width: 187.5px; */
        /* background-color: #50a050; */
        width: 150px;
        text-align: center;
        margin-left: 37.5px;
        margin-top: 30px;
        .giftName {
          color: #e2e2e2;
          font-size: 20px;
        }
        .price {
          color: #ffff00;
          font-size: 13px;
        }
        .sendGiftBt {
          background-color: #fe2c53;
          width: 100%;
          height: 32px;
          opacity: 1;
          border-radius: 0px 0px 18px 18px;
          line-height: 32px;
          color: #fff;
          font-size: 20px;
          display: none;
        }
        img {
          width: 100px;
          height: 100px;
        }
      }
      .giftItem-active {
        width: 150px;
        text-align: center;
        margin-left: 37.5px;
        margin-top: 30px;
        .giftName {
          color: #e2e2e2;
          font-size: 20px;
        }
        .price {
          color: #ffff00;
          font-size: 13px;
        }
        .sendGiftBt {
          background-color: #fe2c53;
          width: 100%;
          height: 32px;
          opacity: 1;
          border-radius: 0px 0px 18px 18px;
          line-height: 32px;
          color: #fff;
          font-size: 20px;
        }
        img {
          width: 100px;
          height: 100px;
        }
      }
    }
  }
  .lotteryContainer {
    width: 90%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .qrCodeImg {
      width: 500px;
      height: 500px;
    }
    .tip {
      color: #333;
      font-size: 25px;
      margin-top: 10px;
    }
  }
  .prizeItem {
    background-color: #fff;
    border-radius: 20px;
    margin: 30px;
    padding: 40px;
    position: relative;
    border: 1px solid #28c76f;
    .statusWrap {
      width: 140px;
      height: 140px;
      position: absolute;
      bottom: 20px;
      right: 30px;
      background-size: 80% 80%;
      //background-image: url("~@/assets/icons/icon_no_receive.png");
      background-repeat: no-repeat;
      background-position: center;
      //z-index: 100;
      //border: 1px solid #FF33DD;
      //background-color: #fff;
    }
    .btWrap {
      width: 160px;
      height: 60px;
      position: absolute;
      bottom: 40px;
      right: 50px;
      background-color: #28c76f;
      color: #fff;
      font-size: small;
      font-weight: 500;
      border-radius: 10px;
      text-align: center;
      line-height: 60px;
    }
    .mainWrap {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      border-bottom: 1px dashed #f2f2f2;
      //border: 1px solid #FF33DD;
      img {
        width: 180px;
      }
      .infoWrap {
        //border: 1px solid #FF33DD;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin-left: 30px;
        .title {
          color: #28c76f;
          font-size: 46px;
        }
      }
    }
    .slaveWrap {
      display: flex;
      flex-direction: row;
      font-size: small;
      //border: 1px solid #FF33DD;
      margin-top: 10px;
      //padding: 5px 0;
      .left {
        display: flex;
        flex-direction: column;
        font-size: 26px;
        color: #7c848e;
        .prizeName {
          font-size: 24px;
          color: #44405e;
        }
      }
    }
  }
}
</style>
