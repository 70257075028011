/**
 * axios配置
 */
import Vue from "vue";
import VueAxios from "vue-axios";
import axios from "axios";
import wx from "weixin-js-sdk";
import $store from "@/store";
import dialog from "@utils/dialog";
import { VUE_APP_CLOUD_API } from "@/utils/index";

Vue.use(VueAxios, axios);

// 设置统一的url
(axios.defaults.baseURL =
  process.env.NODE_ENV === "production" ? VUE_APP_CLOUD_API : "/"), // api 的 base_url
  /* 请求拦截器 */
  axios.interceptors.request.use(
    (config) => {
      // 添加token到header
      const token = $store.state.app.token;
      // const token = setting.takeToken();
      if (token) {
        // config.headers[setting.tokenHeaderName] = token;
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

/* 响应拦截器 */
axios.interceptors.response.use(
  (response) => {
    // debugger
    const { code, msg } = response.data;
    if (code === "00000") {
      return response.data;
    } else {
      // 响应数据为二进制流处理(Excel导出)
      if (response.data instanceof ArrayBuffer) {
        return response;
      }
      // dialog.error(msg || "系统出错");
      // this.$message.error(msg ||'系统出错');
      return Promise.reject(new Error(msg || "系统出错"));
    }
  },
  (error) => {
    const { msg, code } = error.response.data;
    if (code === "A0230") {
      const liveId = localStorage.getItem("h5LiveId");
      wx.miniProgram.navigateTo({
        url: `/pages/liveWelcome/liveWelcome?liveId=${liveId}`,
      });
    } else {
      dialog.error(msg || "系统出错");
      return Promise.reject(new Error(msg || "系统出错"));
    }
  }
);

/**
 * 跳转到登录页面
 */
// function goLogin(reload) {
//   dialog.toast({ mes: "授权超时，重新登录" });
//   $store.commit("LOGOUT");
//   if (reload) {
//     location.replace("/"); // 这样跳转避免再次登录重复注册动态路由
//   } else {
//     const path = router.currentRoute.path;
//     return router.push({
//       path: "/",
//       query: path && path !== "/" ? { form: path } : null,
//     });
//   }
// }
