/**
 * 活动状态管理
 */
import axios from "axios";
export default {
  namespaced: true,
  state: {
    id: null,
    liveTitle: "",
    liveType: "",
    creator: {},
    pcBackUrl: "",
    wxShareUrl: "",
    phoneBackUrl: "",
    isGamePlaying: false,
    isLotteryPlaying: false,
    playingGameId: null,
    drawingId: null,
    signBackUrl: "",
    wxLiveList: [],
    goldThreshold: 0,
    goldLotterySwitch: false,
    maxPrizeVal: 0,
  },
  mutations: {
    setLiveId(state, val) {
      state.id = val;
      localStorage.setItem("h5LiveId", val);
    },
    initLive(state, obj) {
      state.id = obj.id;
      state.creator = obj.creator;
      state.liveTitle = obj.liveTitle;
      state.pcBackUrl = obj.pcBackUrl;
      state.signBackUrl = obj.signBackUrl;
      state.phoneBackUrl = obj.phoneBackUrl;
      state.wxShareUrl = obj.wxShareUrl;
      state.isLotteryPlaying = obj.isLotteryPlaying;
      state.drawingId = obj.drawingId;
      state.isGamePlaying = obj.isGamePlaying;
      state.playingGameId = obj.playingGameId;
      state.wxLiveList = obj.wxLiveList;
      state.goldThreshold = obj.goldThreshold;
      state.goldLotterySwitch = obj.goldLotterySwitch;
      state.maxPrizeVal = obj.maxPrizeVal;
    },
    showGameReady(state, msg) {
      state.isGamePlaying = true;
      state.playingGameId = msg.liveGameId;
    },
    showLotteryReady(state, obj) {
      state.isLotteryPlaying = true;
      state.drawingId = obj.id;
    },
    refreshWxLive(state, wxLiveList) {
      state.wxLiveList = wxLiveList;
    },
    closeGameReady(state) {
      state.isGamePlaying = false;
      state.playingGameId = null;
    },
    closeLottery(state) {
      state.isLotteryPlaying = false;
      state.drawingId = null;
    },
  },
  actions: {
    joinLottery({ commit }, objData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/dlr-wapp/api/lottery/joinLottery", objData)
          .then((res) => {
            // debugger
            commit("closeLottery");
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getLiveInfo({ commit }, liveId) {
      return new Promise((resolve, reject) => {
        axios
          .get("/dlr-wapp/live/getDetail", { params: { id: liveId } })
          .then((res) => {
            commit("initLive", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
  },
};
