import { render, staticRenderFns } from "./IFrame.vue?vue&type=template&id=5986944b&scoped=true"
import script from "./IFrame.vue?vue&type=script&lang=js"
export * from "./IFrame.vue?vue&type=script&lang=js"
import style0 from "./IFrame.vue?vue&type=style&index=0&id=5986944b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5986944b",
  null
  
)

export default component.exports