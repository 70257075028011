export default [
  // {
  //   path: "/game/gameReady",
  //   name: "GameReady",
  //   meta: {
  //     title: "游戏准备中",
  //     keepAlive: true,
  //     auth: true,
  //   },
  //   component: () => import("@views/game/GameReady.vue"),
  // },
  {
    path: "/game/gameRank",
    component: () => import("@views/game/GameRank"),
    meta: { title: "成绩排名" },
  },
  {
    path: "/game/handWriteSign",
    component: () => import("@views/game/handWriteSign/index"),
    meta: { title: "手写签到" },
  },
  {
    path: "/game/redPacketRain",
    component: () => import("@views/game/redPacketRain/index"),
    meta: { title: "抢红包" },
  },
  {
    path: "/game/shake",
    component: () => import("@views/game/shake/index"),
    meta: { title: "摇一摇" },
  },
];
