import app from "./app";
import live from "@/store/modules/live";
import liveGame from "@/store/modules/liveGame";
import lottery from "@/store/modules/lottery";
export default {
  app,
  live,
  liveGame,
  lottery,
};
