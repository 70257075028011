export default {
  // footer: (state) => state.app.footer,
  // homeActive: (state) => state.app.homeActive,
  live: (state) => state.live,
  liveGame: (state) => state.liveGame,
  lottery: (state) => state.lottery,
  token: (state) => state.app.token,
  isLogin: (state) => !!state.app.token,
  // backgroundColor: (state) => state.app.backgroundColor,
  userInfo: (state) => state.app.userInfo || {},
  // storeItems: (state) => state.app.storeItems || {},
  // goName: (state) => state.app.goName,
};
