<template>
  <div>
    <div class="app" v-cloak>
      <keep-alive :include="include" :max="10">
        <router-view class="router" ref="router"></router-view>
      </keep-alive>
    </div>
    <GoldCoin v-if="isLogin" />
  </div>
</template>
<script>
import store from "@/store";
import { parseQuery } from "@utils/index";
import GoldCoin from "@views/home/model/GoldCoin";
function isKeepAlive($route) {
  return $route.meta.keepAlive === undefined || $route.meta.keepAlive;
}

import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  data() {
    return {
      transitionName: "fold-right",
      include: isKeepAlive(this.$route) ? [this.$route.name] : [],
      history: [],
    };
  },
  components: {
    GoldCoin,
  },
  provide() {
    return {
      app: this,
    };
  },
  created() {
    // const { access_token, expires_in } = res.data;
    let token = this.$route.query.token || parseQuery()["token"];
    let liveId = this.$route.query.liveId || parseQuery()["liveId"];
    let expires_in = this.$route.query.expires_in || parseQuery()["expires_in"];
    store.commit("LOGIN", token, dayjs(expires_in));
    store.commit("live/setLiveId", liveId);
    store.dispatch("live/getLiveInfo", liveId);
    store.dispatch("USERINFO").then(() => {
      this.$socketApi.createWebSocket();
    });
  },
  mounted() {
    this.$socketApi.getSock(this.handleSocketMsg);
  },
  computed: mapGetters(["isLogin"]),
  watch: {
    $route(to, from) {
      const lastPath = this.history[this.history.length - 1] || {},
        { isReplace, isBack } = this.$router;

      if (lastPath.path === to.path) {
        this.transitionName = "fold-right";
        this.history.pop();
      } else {
        this.transitionName = "fold-left";
        if (!isReplace) this.history.push({ path: from.path, name: from.name });
      }

      if (isKeepAlive(to) && to.name !== "Login") {
        !this.include.includes(to.name) && this.include.push(to.name);
      }

      if (isKeepAlive(from) && isBack) {
        var index = this.include.indexOf(from.name);
        index !== -1 && this.include.splice(index, 1);
      }

      this.$router.isBack = false;
      this.$router.isReplace = false;

      console.log(this.transitionName, "change");
    },
  },
  destroyed() {
    this.$socketApi.closeWebSocket();
  },
  methods: {
    handleSocketMsg(msg) {
      switch (msg.msgType) {
        case "H0000": // 心跳
          // console.log("返回心跳,", msg);
          break;
        case "L0001": //发起抽奖
          this.$Bus.$emit("readyLottery", msg.data);
          break;
        case "L0003":  //中奖通知
          this.$Bus.$emit("noticeWinner", msg.data);
          break;
        case "M2003": // 清除活动
          this.$Bus.$emit("goHome", msg);
          this.$router.replace({ path: "/" });
          break;
        case "M1002": // 发送聊天消息
          this.$Bus.$emit("sendMsg", msg);
          break;
        case "M1001": // 发送礼物
          // debugger
          this.$Bus.$emit("sendGift", msg);
          break;
        case "M0001": // 连接成功
          // console.log("iChat连接成功!");
          this.$toast("iChat连接成功！");
          break;
        case "M3001": // 准备游戏
          this.$Bus.$emit("readyGame", msg);
          break;
        case "M3002": // 开始游戏
          this.$store.commit("liveGame/upStatus", 1);
          this.$toast("游戏开始！");
          this.$Bus.$emit("startGame", msg);
          break;
        case "M3003": // 结束游戏
          this.$Bus.$emit("endGame", msg);
          this.$router.push("/game/gameRank");
          break;
        default:
          console.log("无效消息" + msg.msgType);
          break;
      }
    },
  },
};
</script>
<style lang="less">
[v-cloak] {
  display: none !important;
}

.router {
  position: absolute;
  width: 100%;
}

.fold-left-enter-active {
  animation-name: fold-left-in;
  animation-duration: 0.5s;
}

.fold-left-leave-active {
  animation-name: fold-left-out;
  animation-duration: 0.5s;
}

@keyframes fold-left-in {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  10% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fold-left-out {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fold-right-enter-active {
  animation-name: fold-right-in;
  animation-duration: 0.5s;
}

.fold-right-leave-active {
  animation-name: fold-right-out;
  animation-duration: 0.5s;
}

@keyframes fold-right-in {
  0% {
    width: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  10% {
    width: 100%;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fold-right-out {
  0% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  10% {
    width: 100%;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    width: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
</style>
