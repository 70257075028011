<template>
  <div class="goldCoinWrap">
    <img src="~@/assets/icons/icon_gold_coin.png" />金币{{ userInfo.nowMoney }}
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $store from "@/store";
export default {
  name: "GoldCoin",
  computed: mapGetters(["userInfo"]),
  mounted() {
    this.$Bus.$on("refreshCoin", () => {
      // console.log("更新金币------------");
      $store.dispatch("USERINFO");
    });
  },
};
</script>

<style lang="less" scoped>
.goldCoinWrap {
  position: fixed;
  top: 94px;
  right: 28px;
  //width: 300px;
  padding: 0 20px;
  height: 75px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 38px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 26px;
  color: #ffffff;
  //color: #16ac57;
  z-index: 1000;
  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 8px;
  }
}
</style>
